




























































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  selectByUserId,
  batchDelShoppingCart,
  updateShoppingCart,
  delShoppingCart,
  checkCustomerStatus
} from "@/api";

interface IProduct {
  id: string
  createTime: string
  isChecked: boolean
  partnumber: string
  mft: string
  description: string
  amount: number
  time: string
  rsp: Array<string>
  stockstatus: Array<string>
  unitPrice: number
  totalPrice: number
  materialId : string
  isValid: number
  pc:string
  goodsUuid:string

}

@Component({
  components: {},
})
export default class Search extends Vue {
  checkAll = false
  checkedProducts: Array<string> = []
  productIds: Array<string> = []
  isIndeterminate = false
  products: Array<IProduct> = []
  totalPrice = 0

  mounted(): void {
    // this.getCart();
    // console.log(this.products.length)
    this.selectByUserId();
  }

  // getCart(): void {
  //   for (let i = 1; i < 4; i++) {
  //     this.products.push({
  //       id: i,
  //       createTime: '2021-4-16 15:00',
  //       isChecked: false,
  //       partnumber: 'AD9625BBPZ-2.0',
  //       mft: 'Analog Devices Inc',
  //       description: '1-Channel Single ADC Pipelined 2Gsps 12-bit JESD204B 196-Pin TEBGA Tray',
  //       amount: 120,
  //       time: '1W',
  //       rsp: ['1：¥100.00', '10：¥1000.00', '100：¥10000.00'],
  //       stockstatus:[],
  //       totalPrice:1000,
  //       unitPrice:1000,
  //       materialId:0,
  //       isValid:1
  //     })
  //   }
  //   this.productIds = this.products.map((item) => item.id)
  // }

  handleBuy(): void {
    //console.log(this.totalPrice)
    // this.$router.push('/confirm-order')
    let checkMaterialIds = [];
    for(let i = 0,len = this.products.length;i<len;i++){
      let item = this.products[i];
      console.log(item)
      if( this.checkedProducts.indexOf(item.id) !== -1 && item.isValid == 1){
        // checkMaterialIds.push(item.materialId+"_"+item.pc);
        checkMaterialIds.push(item.goodsUuid);

      }
    }
    // console.log(checkMaterialIds.join())

 checkCustomerStatus()
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            this.$router.push({ path: '/confirm-order', query: { checkedProducts : `${checkMaterialIds.join()}` , from : 'cart'}});
          } else if(res.data.code == 401) {
            console.log(res.data.msg)
          }else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });

  }

  calculatePrice(): void {
    
    this.totalPrice = this.products.reduce((init: number, item: IProduct) => {
      // console.log(init)
      // console.log(this.checkedProducts)
      if (this.checkedProducts.indexOf(item.id) !== -1 && item.isValid == 1) {
        return init + item.totalPrice
      } else {
        return init
      }
    }, 0)
  }

  handleCheckAllChange(val: string): void {
    console.log(val)
    this.checkedProducts = val ? this.productIds : []
    this.isIndeterminate = false
    this.calculatePrice()
  }

  handleCheckedProductsChange(value: Array<string>): void {
    
    let checkedCount = value.length
    this.checkAll = checkedCount === this.products.length
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.products.length
    this.calculatePrice()
  }

  handleAmountChange(item: IProduct): void {
    // console.log(item)
    this.$nextTick(() => {
      if (item.amount === undefined) {
        item.amount = 1
      }
      this.updateShoppingCart(item)
      
    })
  }

  removeById(id: string): void {
    this.delShoppingCart(id);
    // this.products = this.products.filter((item) => item.id !== id)
    // this.checkedProducts = this.checkedProducts.filter((item) => item !== id)
    // this.setIndeterminate()
    // this.calculatePrice()
  }

  removeSelected(): void {
    this.batchDelShoppingCart();
    // this.products = this.products.filter((item) => this.checkedProducts.indexOf(item.id) === -1)
    // this.checkedProducts = []
    // this.setIndeterminate()
    // this.calculatePrice()
  }

  setIndeterminate(): void {
    this.isIndeterminate = this.checkedProducts.length > 0 && this.checkedProducts.length < this.products.length
  }

    //列表
  selectByUserId(): void {
  
    selectByUserId()
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            this.products = res.data.data;
            // this.productIds = this.products.map((item) => {if(item.isValid == 1){return item.id}})
            for(let i = 0;i<this.products.length;i++){
              if(this.products[i].isValid == 1){
                this.productIds.push(this.products[i].id)
              }
            }
            // console.log(this.productIds)
            // this.addressList = res.data.data;
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  //修改
  updateShoppingCart(item: IProduct): void {
    // console.log(this.orderAmount)
    // if(this.orderAmount == null || this.orderAmount < 1){
    //   this.$message.warning("加入购物车数量至少为1");
    //   return ;
    // }
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      // partnumber: this.productDetail.partnumber,
      // qty: this.productDetail.qty,
      // mft: this.productDetail.mft,
      // rsp: this.productDetail.rsp,
      // stockstatus : this.productDetail.stockstatus,
      // amount : this.orderAmount,  
      // materialId:this.productDetail.id
      id:item.id,
      amount:item.amount
    };
    updateShoppingCart(data)
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            this.products = res.data.data;
            this.productIds = this.products.map((item) => item.id)
            this.calculatePrice()
            // console.log(this.products)
            // console.log(this.productIds)
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            // this.$message.success("添加成功");
            // this.dialogFormVisible = false;
            // this.listUserAddress();
            
          } else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });
  }

   //删除
  delShoppingCart(id: string): void {
  
    delShoppingCart(id)
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            this.products = res.data.data;
            this.productIds = this.products.map((item) => item.id)
            this.products = this.products.filter((item) => item.id !== id)
            this.checkedProducts = this.checkedProducts.filter((item) => item !== id)
            this.setIndeterminate()
            this.calculatePrice()
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            // this.$message.success("添加成功");
            // this.dialogFormVisible = false;
            // this.listUserAddress();
            
          } else if(res.data.code == 401) {
            console.log(res.data.msg)
          }else{
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });

 
  }

   //批量删除
  batchDelShoppingCart(): void {
  
    batchDelShoppingCart(this.checkedProducts)
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            this.products = res.data.data;
            this.productIds = this.products.map((item) => item.id)
            this.checkedProducts = []
            // this.products = this.products.filter((item) => item.id !== id)
            // this.checkedProducts = this.checkedProducts.filter((item) => item !== id)
            this.setIndeterminate()
            this.calculatePrice()
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            // this.$message.success("添加成功");
            // this.dialogFormVisible = false;
            // this.listUserAddress();
            
          } else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });

 
  }
}
